<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card" shadow="always">
          <div class="user-info">
            <img src="~@/assets/img/heizi.jpg" alt="" />
            <div>
              <p class="user-name">Admin</p>
              <p class="user-identity">超级管理员</p>
            </div>
          </div>
          <div>
            <p>上次登陆时间:<span>2021-7-19</span></p>
            <p>上次登陆地点:<span>武汉</span></p>
          </div>
        </el-card>
        <span></span>
        <el-card shadow="always" class="dataShow">
          <el-table :data="tableData" style="width: 100%; height: 335px">
            <el-table-column
              v-for="(label, content) in dataLabel"
              :key="content"
              :prop="content"
              :label="label"
            >
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="16" class="rightPart">
        <el-card
          v-for="item in countData"
          :key="item.name"
          class="countData"
          :body-style="{ display: 'flex', padding: 0 }"
        >
          <i
            :class="`el-icon-${item.icon}`"
            :style="{ backgroundColor: item.color, color: '#fff' }"
          ></i>
          <!--                这里注意不能使用常规的style的动态的绑定语法，否则你的左值也会被解析，但他会发现Vue实例对象上没有这个值-->
          <!--                在Vue中如果对style做绑定要传入一个对象-->
          <div>
            <p class="value">￥{{ item.value }}</p>
            <p class="name">{{ item.name }}</p>
          </div>
        </el-card>
        <span></span>
        <el-card class="zhexian" ref="echarts1"> 折线图部分 </el-card>
        <div class="smallpics">
          <el-card class="pic"> 柱状图 </el-card>
          <el-card class="pic"> 扇形图 </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  components: {},
  data() {
    return {
      tableData: [
        {
          name: 'oppo',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
        {
          name: 'vivo',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
        {
          name: '苹果',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
        {
          name: '小米',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
        {
          name: '三星',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
        {
          name: '魅族',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800,
        },
      ],
      countData: [
        {
          name: '今日支付订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9',
        },
        {
          name: '今日收藏订单',
          value: 210,
          icon: 'star-on',
          color: '#ffb980',
        },
        {
          name: '今日未支付订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef',
        },
        {
          name: '本月支付订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9',
        },
        {
          name: '本月收藏订单',
          value: 210,
          icon: 'star-on',
          color: '#ffb980',
        },
        {
          name: '本月未支付订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef',
        },
      ],
      dataLabel: {
        name: '课程',
        todayBuy: '今日购买',
        monthBuy: '本月购买',
        totalBuy: '总购买',
      },
    };
  },
  // mounted() {
  //   // 基于准备好的dom，初始化echarts实例
  //   const echarts1 = echarts.init(this.$refs.echarts1)
  //   // 指定图表的配置项和数据
  //   var echarts1Option = {}
  //   const {orderData} = data.data
  //   const  legend = Object.keys(orderData[0])
  //   echarts1Option.xAxis = {
  //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //     type:'category',
  //     boundaryGap:true
  //   }
  //   echarts1Option.legend = {
  //     data:legend
  //   }
  //   echarts1Option.yAxis = {}
  //   echarts1Option.series = []
  //   xAxis.forEach(key => {
  //     echarts1Option.series.push({
  //       name: key,
  //       data: orderData.data.map(item => item[key]),
  //       type: 'line'
  //     })
  //   })
  //   echarts1.setOption(echarts1Option)
  // },
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  height: 150px;
  margin-right: 40px;
  border-radius: 50%;
}
.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  .user-name {
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;
  }
  .user-identity {
    margin: 0;
  }
}
span {
  margin-left: 60px;
}
i {
  height: 80px;
  width: 80px;
  /*可以通过font-size控制i中的图标大小，为什么？？font-size本质上是控制字符框的高度 */
  font-size: 30px;
  line-height: 80px;
  text-align: center;
}
p {
  margin: 0;
}

.countData {
  width: 32%;
  margin-right: 9px;
  margin-bottom: 14px;
  i {
    height: 80px;
    width: 80px;
    font-size: 30px;
    line-height: 80px;
    text-align: center;
  }
  .value {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .name {
    text-align: center;
    color: #999;
    font-size: 14px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
  }
}
.rightPart {
  display: flex;
  flex-wrap: wrap;
}
.el-col {
  padding: 10px;
}
.zhexian {
  width: 100%;
  height: 250px;
}
.smallpics {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  .pic {
    height: 195px;
    width: 48%;
  }
}
</style>
